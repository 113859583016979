import React from 'react';
import Reveal from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
// import SliderMainParticleGrey from '../components/SliderMainParticleGrey1';
// import CarouselCollectionRedux from '../components/CarouselCollectionReduxNew';
// import CarouselNewRedux from '../components/CarouselNewReduxNew';
// import AuthorListRedux from '../components/AuthorListRedux';
// import Catgor from '../components/Catgor';
// import Footer from '../components/footer';
import { createGlobalStyle, keyframes } from 'styled-components';

const authors = [
  {
    "avatar": "/img/author/author-1.jpg",
    "username": "Monica Lucas",
    "sales": 3.2
  },
  {
    "avatar": "/img/author/author-2.jpg",
    "username": "Mamie Barnett",
    "sales": 2.8
  },
  {
    "avatar": "/img/author/author-3.jpg",
    "username": "Nicholas Daniels",
    "sales": 2.5
  },
  {
    "avatar": "/img/author/author-4.jpg",
    "username": "Lori Hart",
    "sales": 2.2
  },
  {
    "avatar": "/img/author/author-5.jpg",
    "username": "Jimmy Wright",
    "sales": 1.9
  },
  {
    "avatar": "/img/author/author-6.jpg",
    "username": "Karla Sharp",
    "sales": 1.6
  },
  {
    "avatar": "/img/author/author-7.jpg",
    "username": "Gayle Hicks",
    "sales": 1.5
  },
  {
    "avatar": "/img/author/author-8.jpg",
    "username": "Claude Banks",
    "sales": 1.3
  },
  {
    "avatar": "/img/author/author-9.jpg",
    "username": "Franklin Greer",
    "sales": 0.9
  },
  {
    "avatar": "/img/author/author-10.jpg",
    "username": "Stacy Long",
    "sales": 0.8
  },
  {
    "avatar": "/img/author/author-11.jpg",
    "username": "Ida Chapman",
    "sales": 0.6
  },
  {
    "avatar": "/img/author/author-12.jpg",
    "username": "Fred Ryan",
    "sales": 0.5
  }
]

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

export default function Home() {
  return (
    <div>
      <GlobalStyles />

      <section className="jumbotron breadcumb no-bg h-vh" style={{ backgroundImage: `url(${'./img/background/11.jpg'})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="spacer-single"></div>
              <h6> <span className="text-uppercase color">Gigaland Market</span></h6>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
                <h1 className="col-white">Discover <span className="color">rare</span> digital art and collect NFTs</h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
                <p className="lead col-white">
                  Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
                </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
                <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
                <span onClick={() => window.open("#", "_self")} className="btn-main inline white lead">Create</span>
                <div className="mb-sm-30"></div>
              </Reveal>

              <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
                <div className="row">
                  <div className="spacer-single"></div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                      <div className="de_count text-left">
                        <h3><span>94215</span></h3>
                        <h5 className="id-color">Collectibles</h5>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                      <div className="de_count text-left">
                        <h3><span>27</span>k</h3>
                        <h5 className="id-color">Auctions</h5>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                      <div className="de_count text-left">
                        <h3><span>4</span>k</h3>
                        <h5 className="id-color">NFT Artist</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-image"></i>
              <span>Art</span>
            </Link>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-music"></i>
              <span>Music</span>
            </Link>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-search"></i>
              <span>Domain Names</span>
            </Link>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-globe"></i>
              <span>Virtual Worlds</span>
            </Link>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-vcard"></i>
              <span>Trading Cards</span>
            </Link>
          </div>
          <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
              <i className="fa fa-th"></i>
              <span>Collectibles</span>
            </Link>
          </div>
        </div>
      </section>

      <section className='pb60 yelbg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>Top Sellers</h2>
            </div>
            <div className='col-lg-12'>
              <div>
                <ol className="author_list">
                  {authors && authors.map((author, index) => (
                    <li key={index}>
                      <>
                        <div className="author_list_pp">
                          <span onClick={() => window.open("", "_self")}>
                            <img className="lazy" src={author.avatar} alt="" />
                            <i className="fa fa-check"></i>
                          </span>
                        </div>
                        <div className="author_list_info">
                          <span onClick={() => window.open("", "_self")}>{author.username}</span>
                          <span className="bot">{author?.author_sale?.sales} ETH</span>
                        </div>
                      </>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='pb60 yelbg-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>Hot Collections</h2>
            </div>
            <div className='col-lg-12'>
              {/* <CarouselCollectionRedux /> */}
            </div>
          </div>
        </div>
      </section>

      <section className='pb60 greybg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>New Items</h2>
            </div>
            <div className='col-lg-12'>
              {/* <CarouselNewRedux /> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}