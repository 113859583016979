import React, { useContext, useEffect, useMemo, useState } from "react";
import { createGlobalStyle, keyframes } from "styled-components";
import Reveal from "react-awesome-reveal";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Coins,
  ChartBars,
  Referral,
  BarsUser,
  Briefcase,
  PieChartIcon,
  Wallet,
  ChartBars2,
  Doller,
  Safe,
} from "../assets/dashboard";
import TableComponent from "../components/TableComponent";
import axiosClient from "../utils/AxiosClient";
import { mHeaders } from "../utils";
import { columnsData1, columnsData2, columnsData3, columnsData4 } from "./EarningsMetaData/EarningsMetaData";
import { UserContext } from "../App";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: start;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    left: 40%;
    top: 40%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const iconSize = 40;
const iconSize2 = 80;
const iconColor = "#fff";
const iconColor2 = "rgba(6,174,177,0.1)";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const cards = [
  {
    name: "Referral Income",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "referral_earning",
    doller: true,
  },
  {
    name: "Global Income",
    icon: Coins(iconSize, iconColor),
    icon2: Coins(iconSize2, iconColor2),
    doller: true,
    key: "global_earning",
  },
  {
    name: "Rank Income",
    icon: BarsUser(iconSize, iconColor),
    icon2: BarsUser(iconSize2, iconColor2),
    doller: true,
    key: "rank_earning",
  },
  {
    name: "Special Rank Income",
    value: '$ 0',
    icon: ChartBars2(iconSize, iconColor),
    icon2: ChartBars2(iconSize2, iconColor2),
    key: "special_rank_earning",
  }
];

const Earning = () => {
  const { data: customerData } = useContext(UserContext);
  const [data, setData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
    data6: [],
    data7: [],
  });

  const columns1 = useMemo(() => columnsData1, []);
  const columns3 = useMemo(() => columnsData3, []);
  const columns4 = useMemo(() => columnsData4, []);
  const columns2 = useMemo(() => columnsData2, []);

  const mTable1 = useTable({ columns: columns1, data: data?.data1 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  // const mTable2 = useTable({ columns: columns1, data: data?.data2 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  const mTable3 = useTable({ columns: columns3, data: data?.data3 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  const mTable4 = useTable({ columns: columns2, data: data?.data4 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  const mTable5 = useTable({ columns: columns2, data: data?.data5 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  const mTable6 = useTable({ columns: columns4, data: data?.data6 }, useFilters, useGlobalFilter, useSortBy, usePagination);
  const mTable7 = useTable({ columns: columns4, data: data?.data7 }, useFilters, useGlobalFilter, useSortBy, usePagination);

  const getEarnings = async () => {
    try {
      const { data: data1 } = await axiosClient.get("/earning/customer/referral-earning", { headers: mHeaders });
      // const { data: data2 } = await axiosClient.get("/earning/customer/performance-earning", { headers: mHeaders });
      const { data: data3 } = await axiosClient.get("/earning/customer/global-level-earning", { headers: mHeaders });
      const { data: data4 } = await axiosClient.get("/earning/customer/rank-earning", { headers: mHeaders });
      const { data: data5 } = await axiosClient.get("/earning/customer/special-rank-earning", { headers: mHeaders });
      const { data: data6 } = await axiosClient.get("/earning/customer/royalty-earning", { headers: mHeaders });
      const { data: data7 } = await axiosClient.get("/earning/customer/sponsor-royalty-earning", { headers: mHeaders });
      setData({ data1, data3, data4, data5, data6, data7 });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEarnings();
  }, []);

  return (
    <div>
      <GlobalStyles />

      <section className="container pt-0 mt-3 pt-md-5 mt-md-5 pb-0 p-3">
        <div className="row">
          <div className="col-12 order-md-first order-last">
            <div className="row">
              {cards?.map((card, index) => (
                <div key={index} className="col-lg-3 col-md-6 mb-4">
                  <div className="feature-box h-100 d-flex align-items-center justify-content-between f-boxed style-3">
                    <div className="text mt-2">
                      <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                        <p className="fs-20 mb-2">{card.name}</p>
                      </Reveal>
                      <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                        <p style={{ color: 'black' }} className="fs-25 fw-600">{card.doller ? '$ ' : ''} {customerData[card.key] ? customerData[card.key] : 0}</p>
                      </Reveal>
                    </div>
                    <Reveal className="onStep" keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                      <div className="mcard-icon">{card.icon}</div>
                    </Reveal>

                    <i className="wm">{card.icon2}</i>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white p-4 mb-4 rounded">
          <div className="row">
            <div className="col-md-6">
              <TableComponent mTable={mTable1} title="Referral Income" />
            </div>
            <div className="col-md-6">
              <TableComponent mTable={mTable3} title="Global Level Income" />
            </div>
            <div className="col-md-6">
              <TableComponent mTable={mTable4} title="Rank Income" />
            </div>
            <div className="col-md-6">
              <TableComponent mTable={mTable5} title="Share Income" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Earning;