import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import React, { useContext, useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components';
import axiosClient from '../utils/AxiosClient';
import { UserContext } from '../App';
import { getContractMLM } from "../utils/index";
import Swal from 'sweetalert2';
import { BiRocket } from 'react-icons/bi'

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

export default function Login() {
  const { open } = useWeb3Modal();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider()
  const [sponsorAddr, setSponsorAddr] = useState('');
  const [mAddress, setMAddress] = useState('');
  const userContext = useContext(UserContext);

  useEffect(() => {
    const mTimeout = setTimeout(() => {
      if (!address) {
        open();
      }
    }, 1000)

    return () => {
      clearTimeout(mTimeout);
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      setMAddress(address);
      checkUser(address);
    }
  }, [address]);

  const checkUser = async (mAddress) => {
    if(localStorage.getItem("CustomerToken")){
      window.location.href = "/dashboard";
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if(address){
      const { Contract: MLMContract } = await getContractMLM(walletProvider);
      try {
        const resp1 = await MLMContract.isRegistered(mAddress);
        if (resp1) {
          const { data: mData } = await axiosClient.post("/user/customer/login/", { wallet_address: mAddress });
          localStorage.setItem("CustomerData", JSON.stringify(mData));
          localStorage.setItem("CustomerToken", mData?.access_token);
          userContext?.setData(mData);
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/register";
        }
      } catch (error) {
        window.location.href = "/register";
      }
    } else {
      open();
    }
  }

  return (
    <>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})`, minHeight: '100vh' }}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="col-lg-5 text-light wow fadeInRight" data-wow-delay=".5s">
                <div className="spacer-10"></div>
                <h1>Create, sell or collect digital items.</h1>
                <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim.</p>
              </div>
              <div className="col-lg-5 offset-lg-2 wow fadeIn" data-wow-delay=".5s">
                <div className="box-login">
                  <h3 className="mb10">Login Now</h3>
                  <p>Login to continue <BiRocket /></p>
                  <form name="contactForm" id='contact_form' method='post' onSubmit={onSubmit} className="form-border" action='#'>

                    <div className="field-set">
                      <input type='text' name='wallet_address' id='wallet_address' className="form-control" placeholder="Wallet Address" disabled value={mAddress} />
                    </div>

                    <div className="field-set">
                      <input type='submit' value='Login' className="btn btn-main btn-fullwidth color-2" />
                    </div>

                    <div className="clearfix"></div>

                    <div className="spacer-single"></div>
                    <span>Click here to <a href="/register" className='text-primary'>register</a></span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}