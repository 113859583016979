import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Breakpoint,
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { UserContext } from "../App";
import useOnclickOutside from "react-cool-onclickoutside";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

export default function Header({ className }) {
  const { data: customerData, setData } = useContext(UserContext);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const { close } = useWeb3Modal();
  const [showmenu, btn_icon] = React.useState(false);

  const logout = () => {
    localStorage.removeItem("CustomerToken");
    localStorage.removeItem("CustomerData");
    setData({});
    close();
    window.location.href = "/";
  };

  const closeMenu2 = () => {
    setOpenMenu2(false);
  };

  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };

  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });

  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img
                  src="/img/logo.png"
                  className="img-fluid d-block"
                  style={{ width: "160px" }}
                  alt="#"
                />
                <img
                  src="/img/logo-light.png"
                  className="img-fluid d-none"
                  style={{ width: "160px" }}
                  alt="#"
                />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu &&
                <div className='menu'>
                  {customerData && customerData?.total_purchase && (
                    <>
                  <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                    <NavLink to="/dashboard">Dashboard</NavLink>
                  </div>
                  <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                    <NavLink to="/my-purchase">My Purchase</NavLink>
                  </div>
                  <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                    <NavLink to="/withdraw">Withdraw</NavLink>
                  </div>
                  <div className="navbar-item ms-0">
                    <div ref={ref2}>
                      <div
                        className="dropdown-custom dropdown-toggle ms-0 me-3 btn"
                        onClick={handleBtnClick2}
                      >
                        Earning
                      </div>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            <NavLink onClick={() => btn_icon(prev => !prev)} to="/income">Income</NavLink>
                            <NavLink onClick={() => btn_icon(prev => !prev)} to="/bonus">Bonus</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                    <NavLink to="/refferal">Referral</NavLink>
                  </div>
                  </>
                  )}
                </div>
              }
            </Breakpoint>
            <Breakpoint xl>
              <div className="menu">
                {customerData && customerData?.total_purchase && (
                  <>
                <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </div>
                <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                  <NavLink to="/my-purchase">My Purchase</NavLink>
                </div>
                <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                  <NavLink to="/withdraw">Withdraw</NavLink>
                </div>

                <div className="navbar-item ms-0">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom dropdown-toggle ms-0 me-3 btn"
                      onClick={handleBtnClick2}
                    >
                      Earning
                    </div>
                    {openMenu2 && (
                      <div className="item-dropdown">
                        <div className="dropdown" onClick={closeMenu2}>
                          <NavLink onClick={() => btn_icon(prev => !prev)} to="/income">Income</NavLink>
                          <NavLink onClick={() => btn_icon(prev => !prev)} to="/bonus">Bonus</NavLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                  <NavLink to="/refferal">Referral</NavLink>
                </div>
                </>
                )}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className="mainside">
            <div className="connect-wal">
              {
                localStorage.getItem("CustomerToken") ? (
                  <button onClick={() => logout()} className="btn-main px-3">
                    Logout
                  </button>
                ) : (
                  <></>
                )
                // <NavLink to="/register">Connect Wallet</NavLink>
              }
            </div>
          </div>
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
}

const NavLink = (props) => <Link {...props} />;
