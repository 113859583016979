import { ethers } from "ethers";

export const mHeaders = {
  Authorization: "Bearer " + localStorage.getItem("CustomerToken"),
};

export const APP_DATA = {
  currency: "$",
  MLMContract: "0x75ea72Af918EA54415A8ccff435dCe95Cc233435",
  MLMABI: require("./abis/MLM.json"),
  OwnerAddress: "0x4f5609b1Ef25679c5ee4477F906D063C318f9532",
  USDTContract: "0x2e6F649d16d77d59c49649687f9D6CdD25FDeBED",
  USDTABI: require("./abis/USDT.json"),
  SECURITY: "0x906378528960De2c5F8774276B01E60357D01894",
  BASE_URL: "https://app.quickpay.international",
  BLOCK_EXP_URL: "https://testnet.bscscan.com",
};

export const getContractMLM = async (walletProvider, type = "MLM") => {
  const provider = new ethers.providers.Web3Provider(walletProvider);
  const signer = provider.getSigner();
  let Contract = new ethers.Contract(
    APP_DATA.MLMContract,
    APP_DATA.MLMABI,
    signer
  );
  if (type == "USDT") {
    Contract = new ethers.Contract(
      APP_DATA.USDTContract,
      APP_DATA.USDTABI,
      signer
    );
  }
  return { Contract };
};

export const formatWalletAddress = (data) => {
  if (!data) return "";
  return data.slice(0, 6) + "..." + data.slice(-4);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatTransactionHash = (data) => {
  if (!data) return "";
  return data.slice(0, 10) + "..." + data.slice(-6);
};
