import React, { useContext, useEffect, useMemo, useState } from "react";
import { createGlobalStyle } from "styled-components";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Modal } from "react-bootstrap";
import axiosClient from "../utils/AxiosClient";
import {
  formatTransactionHash,
  mHeaders,
  capitalizeFirstLetter,
  APP_DATA,
} from "../utils";
import TableComponent from "../components/TableComponent";
import { UserContext } from "../App";
import moment from "moment";
import Swal from "sweetalert2";
import { AiOutlineExport } from "react-icons/ai";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: end;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    right: 5%;
    top: 30%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const Withdraw = () => {
  const [data, setData] = useState([]);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const { data: customerData } = useContext(UserContext);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (props) => props.row.index + 1,
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (props) => (
          <div>
            <p className="m-0" style={{ minWidth: "100px" }}>
              {moment(props.value).format("DD-MM-YYYY")}
            </p>
            <p className="m-0 fs-14 text-gray">
              {moment(props.value).format("LT")}
            </p>
          </div>
        ),
      },
      {
        Header: "Withdraw Amount",
        accessor: "amount",
        Cell: (props) => "$ " + props.value,
      },
      {
        Header: "Fees",
        accessor: "fee",
        Cell: (props) => "$ " + props.value,
      },
      {
        Header: "Amount received",
        accessor: "amount_given",
        Cell: (props) => "$ " + props.value,
      },
      {
        Header: "Transaction Hash",
        accessor: "transaction_hash",
        Cell: (props) => (
          <a
            className="d-flex gap-2"
            href={`${APP_DATA.BLOCK_EXP_URL}/tx/${props.value}`}
            target="__blank"
          >
            {formatTransactionHash(props.value)}{" "}
            {props.value ? <AiOutlineExport /> : <></>}
          </a>
        ),
      },
      {
        Header: "Trans. Status",
        accessor: "status",
        Cell: (props) => (
          <span
            className={`badge badge-${
              props.row.original.approval == "rejected"
                ? "failure"
                : props.value == "success"
                ? "success"
                : "pending"
            }`}
          >
            {capitalizeFirstLetter(
              props.row.original.approval == "rejected"
                ? "rejected"
                : props.value
            )}
          </span>
        ),
      },
    ],
    []
  );

  const mTable = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axiosClient
      .get("/transaction/customer/withdraw/", { headers: mHeaders })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    axiosClient
      .post(
        "/transaction/customer/withdraw/",
        { amount: Number(withdrawAmount) },
        { headers: mHeaders }
      )
      .then((resp) => {
        Swal.fire({
          title: "Withdraw Request Success",
          text: "Your Withdrawal Request Successfully Created.",
          icon: "success",
          timer: 5000,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <GlobalStyles />

      <section className="container pt-0 mt-3 pt-md-5 mt-md-5 pb-0 p-3">
        <TableComponent
          mTable={mTable}
          title="Withdraw"
          component={
            <button
              className="btn-main btn-black ms-3 mb-4"
              onClick={handleShow}
            >
              Withdraw
            </button>
          }
        />
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5">
          <div className="mb-4 fs-20">
            Balance -&nbsp;
            <span className="text-black fw-600">
              {customerData?.wallet_balance ? customerData?.wallet_balance : 0}{" "}
              USDT
            </span>
          </div>
          <form
            name="contactForm"
            id="contact_form"
            method="post"
            onSubmit={onSubmit}
            className="form-border"
            action="#"
          >
            <div className="field-set">
              <label>
                <b>Amount</b>
              </label>
              <input
                type="text"
                name="withdrawAmount"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                className="form-control"
                placeholder="Enter amount"
              />
            </div>
            <div className="d-flex gap-2 justify-content-center">
              <button type="submit" className="btn-main">
                Withdraw
              </button>
              <button
                type="button"
                onClick={handleClose}
                className="btn-main btn-black"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Withdraw;
