import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function TableComponent({ mTable, title = '', component = <></> }) {
  return (
    <div className="border mb-3 p-3 rounded">
      <div className="d-flex justify-content-between flex-wrap mt-3">
        <div className="flex-column">
          <h3 className="fw-bold mb-1">{title}</h3>
          <div className="fs-6 text-gray-400">
            Total {mTable.rows.length} rows
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center position-relative my-1 ms-2">
            <input
              type="text"
              id="kt_filter_search"
              className="form-control form-control-solid form-select-sm w-150px ps-9"
              placeholder="Search ..."
              value={mTable.state.globalFilter}
              onChange={(e) => mTable.setGlobalFilter(e.target.value)}
            />
            {component}
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table
          {...mTable.getTableProps()}
          className="table align-middle rounded overflow-hidden border fs-6 gy-5 dataTable no-footer"
        >
          <thead>
            {mTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={
                      column.render("Header") == "ACTION" ? "w-200px" : ""
                    }
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span className="text-gray-400 fs-7">
                      {column.render("Header")}
                    </span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="arrow_carrot-up fa-lg" />
                      ) : (
                        <i className="arrow_carrot-down fa-lg" />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...mTable.getTableBodyProps()}>
            {(mTable.page.length > 0 &&
              mTable.page.map((row) => {
                mTable.prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })) || (
                <tr>
                  <td colSpan={5} className="text-center">
                    <span className="fw-bold">No data available in table</span>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <div>
            <select
              className="form-select form-select-sm form-select-solid"
              value={mTable.state.pageSize}
              onChange={(e) => mTable.setPageSize(parseInt(e.target.value))}
            >
              {[10, 25, 50, 100].map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div>
            <span>
              Page{" "}
              <strong>
                {mTable.state.pageIndex + 1} of {mTable.pageOptions.length}
              </strong>
            </span>
          </div>
          <div className="d-flex">
            <button
              className="btn-main px-2 mx-2"
              onClick={() => mTable.previousPage()}
              disabled={!mTable.canPreviousPage}
            >
              <IoIosArrowBack />
            </button>
            <button
              className="btn-main px-2 mx-2"
              onClick={() => mTable.nextPage()}
              disabled={!mTable.canNextPage}
            >
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}