import { useWeb3Modal } from "@web3modal/ethers5/react";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/Register";
import Header from "./components/Header";
import { createGlobalStyle } from "styled-components";
import Dashboard from "./pages/Dashboard";
import Investment from "./pages/Investment";
import Withdraw from "./pages/Withdraw";
import Earning from "./pages/Earning";
import Refferal from "./pages/Refferal";
import Home from "./pages/Home";
import BuyPackage from "./pages/BuyPackage";
import { createContext, useEffect, useState } from "react";
import axiosClient from "./utils/AxiosClient";
import { mHeaders } from "./utils";
import Login from "./pages/Login";
import BonusEarning from "./pages/BonusEarning";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const UserContext = createContext({
  data: {},
  setData: () => {},
});

function App() {
  const [data, setData] = useState({});

  useEffect(() => {
    axiosClient
      .get("/user/customer/user-profile/", { headers: mHeaders })
      .then((resp) => {
        setData(resp.data);
        if (
          !resp.data?.total_purchase &&
          window.location.href.indexOf("buy-package") == -1
        ) {
          window.location.href = "buy-package";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <UserContext.Provider value={{ data, setData }}>
      <GlobalStyles />
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-purchase" element={<Investment />} />
        <Route path="/buy-package" element={<BuyPackage />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/income" element={<Earning />} />
        <Route path="/bonus" element={<BonusEarning />} />
        <Route path="/refferal" element={<Refferal />} />

        <Route path="*" element={<></>} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
