import { createGlobalStyle } from "styled-components";
import { BsGlobe } from 'react-icons/bs'
import { useEffect, useState } from "react";
import axiosClient from "../utils/AxiosClient";
import { APP_DATA, getContractMLM, mHeaders } from "../utils";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import Swal from "sweetalert2";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: end;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    right: 5%;
    top: 30%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

export default function BuyPackage() {
  const [data, setData] = useState([])
  const { walletProvider } = useWeb3ModalProvider()
  const { address } = useWeb3ModalAccount();
  const [loading, setLoading] = useState(false);

  function compare(a, b) {
    if (Number(a.amount) < Number(b.amount)) {
      return -1;
    }
    if (Number(a.amount) > Number(b.amount)) {
      return 1;
    }
    return 0;
  }

  const getPackages = async () => {
    try {
      const { data: mData } = await axiosClient.get("/transaction/customer/purchase-choises/", { headers: mHeaders });
      let tempData = JSON.parse(JSON.stringify(mData));
      tempData.sort(compare);
      setData(tempData);
    } catch (error) {

    }
  }

  useEffect(() => {
    getPackages();
  }, [])

  const failedTrans = (pkid) => {
    const mPayload = {
      pkid: pkid,
      status: 'failure',
    }
    axiosClient.patch("/transaction/customer/purchase/", mPayload, { headers: mHeaders }).then(apiResp2 => {
      setLoading(false);
      Swal.fire({
        title: 'Package Buy Error',
        text: 'Some Error occurred while purchasing the package with Web3. Please try again !!!',
        icon: 'error'
      }).then(() => {
        window.location.href = "/my-purchase";
      });
    }).catch(err => {
      setLoading(false);
      console.log(err, "2");
    });
  }

  const redeemNow = async (obj) => {
    // if (error) return;
    setLoading(true);
    const { Contract } = await getContractMLM(walletProvider);
    const USDT = await getContractMLM(walletProvider, "USDT");

    const allowance = await USDT.Contract.allowance(address, APP_DATA.MLMContract);
    const amount = ethers.utils.parseUnits(obj?.amount, "ether")

    axiosClient.post("/transaction/customer/purchase/", { amount: obj.pkid }, { headers: mHeaders }).then(async (apiResp) => {
      if (Number(allowance) <= 0) {
        try {
          const contract1 = await USDT.Contract.approve(APP_DATA.MLMContract, ethers.constants.MaxUint256);
          await contract1.wait();
        } catch (error) { 
          failedTrans(apiResp.data?.pkid);
          return;
        }
        Contract.stake(amount).then(resp => {
          resp.wait().then(resp1 => {
            const mPayload = {
              pkid: apiResp.data?.pkid,
              transaction_hash: resp1.transactionHash,
              status: 'success',
            }
            axiosClient.patch("/transaction/customer/purchase/", mPayload, { headers: mHeaders }).then(apiResp2 => {
              setLoading(false);
              Swal.fire({
                title: 'Package Buy Successfull',
                text: 'The Package successfully purchased.',
                icon: 'success'
              }).then(() => {
                window.location.href = "/my-purchase";
              });
            }).catch(err => {
              setLoading(false);
              console.log(err, "2");
            });
          })
        }).catch(err => {
          failedTrans(apiResp.data?.pkid)
        });
      } else {
        Contract.stake(amount).then(resp => {
          resp.wait().then(resp1 => {
            const mPayload = {
              pkid: apiResp.data?.pkid,
              transaction_hash: resp1.transactionHash,
              status: 'success',
            }
            axiosClient.patch("/transaction/customer/purchase/", mPayload, { headers: mHeaders }).then(apiResp2 => {
              setLoading(false);
              Swal.fire({
                title: 'Package Buy Successfull',
                text: 'The Package successfully purchased.',
                icon: 'success'
              }).then(() => {
                window.location.href = "/my-purchase";
              });
            }).catch(err => {
              setLoading(false);
              console.log(err, "2");
            });
          })
        }).catch(err => {
          failedTrans(apiResp.data?.pkid);
        })
      }
    }).catch(err => {
      setLoading(false);
    })
  }

  return (
    <>
      <GlobalStyles />

      <section className="container section-2 pt-0 mt-3 pt-md-5 mt-md-5 pb-0 p-3">
        <div className="new-item-cards">
          <div className="row">
            {data?.map((ele, index) => (
              <div key={index} className={`col-md-6 col-lg-6 col-xl-${index == data.length - 1 ? '8' : '4'} mb-3`}>
                <div className={"item-card h-100"}>
                  <div>
                    <div className="item-header-profile d-flex gap-2 align-items-center px-2">
                      <div className="m-card-1">
                        <BsGlobe color="#111" />
                      </div>
                      <div className="ms-2">
                        <h5 className="mb-0">$ {ele.amount}</h5>
                        <p className="mb-0">{ele.name.replace("Package", "")}</p>
                      </div>
                    </div>
                    <div className="item-main px-2">
                      {
                        ele?.bullet_points?.map((obj, index) => (
                          <div key={index} className="d-flex gap-2 mb-1">
                            <input type="checkbox" readOnly checked style={{ accentColor: '#f2993a' }} />
                            <p className="mb-0 fs-20">{obj.text}</p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="item-footer px-3 pb-3">
                    <button disabled={loading} onClick={() => !loading && redeemNow(ele)} className={`m-0 item-desc w-100 border-0 cursor-pointer ${loading?'disabled':''}`}>
                      <span>
                        Buy Now
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}