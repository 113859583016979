import moment from "moment";
import { formatWalletAddress } from "../../utils";

export const columnsData1 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
    <p className="m-0" style={{ minWidth: "100px" }}>
      {moment(props.value).format("DD-MM-YYYY")}
    </p>
    <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
  </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  },
  {
    Header: "Earning From",
    accessor: "purchase",
    Cell: (props) => formatWalletAddress(props.value?.customer?.wallet_address)
  },
];

export const columnsData2 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
          <p className="m-0" style={{ minWidth: "100px" }}>
            {moment(props.value).format("DD-MM-YYYY")}
          </p>
          <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
        </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  },
  {
    Header: "Rank",
    accessor: "rank",
  },
];

export const columnsData3 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
          <p className="m-0" style={{ minWidth: "100px" }}>
            {moment(props.value).format("DD-MM-YYYY")}
          </p>
          <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
        </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  },
  {
    Header: "Level",
    accessor: "level",
  },

  {
    Header: "Type",
    accessor: "type",
    Cell: (props) => props.value.charAt(0).toUpperCase() + props.value.slice(1)
  },
];

export const columnsData4 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
          <p className="m-0" style={{ minWidth: "100px" }}>
            {moment(props.value).format("DD-MM-YYYY")}
          </p>
          <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
        </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  },
  {
    Header: "Level",
    accessor: "level",
  },

  {
    Header: "Type",
    accessor: "type",
  },
];

export const columnsData5 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
          <p className="m-0" style={{ minWidth: "100px" }}>
            {moment(props.value).format("DD-MM-YYYY")}
          </p>
          <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
        </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  }
];

export const columnsData6 = [
  {
    Header: "#",
    Cell: (props) => props.row.index + 1,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (props) => <div>
          <p className="m-0" style={{ minWidth: "100px" }}>
            {moment(props.value).format("DD-MM-YYYY")}
          </p>
          <p className="m-0 fs-14 text-gray">{moment(props.value).format("LT")}</p>
        </div>
  },
  {
    Header: "Earning",
    accessor: "earning",
    Cell: (props) => "$ "+props.value
  },
  {
    Header: "Earning From",
    accessor: "royalty_earning",
    Cell: (props) => formatWalletAddress(props.value?.customer?.wallet_address)
  },
];