import React, { useEffect, useMemo, useState } from "react";
import { createGlobalStyle } from "styled-components";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axiosClient from "../utils/AxiosClient";
import { formatTransactionHash, formatWalletAddress, mHeaders } from "../utils";
import { JQueryOrgChart } from "../assets/JQueryOrgChart";
import TableComponent from "../components/TableComponent";
import moment from "moment";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: end;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    right: 5%;
    top: 30%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const Refferal = () => {
  const [treeData, setTreeData] = useState();
  const [tableData, setTableData] = useState([]);

  const getTreeData = () => {
    axiosClient
      .get("/user/customer/user-tree/", { headers: mHeaders })
      .then((resp) => {
        setTreeData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTableData = () => {
    axiosClient
      .get("/user/customer/downline/", { headers: mHeaders })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTreeData();
    getTableData();
  }, []);

  useEffect(() => {
    if (treeData) {
      const script1 = document.createElement("script");
      script1.innerHTML = JQueryOrgChart;
      script1.async = true;
      document.body.append(script1);
      const script = document.createElement("script");
      script.innerHTML = `
        (function($){
          $(function() {
            $('.toggle-social-share').click(function(){
              $('.social-share').toggle('slow');
            });

            var datascource = ${JSON.stringify(treeData)};

            // Vertical Tree
            var verticalLevel = 4;
            if( $(window).width() >= 992){
              verticalLevel = 4;
            }

            var oc = $('.chart-container').orgchart({
              'pan': true,
              'zoom': true,
              'data' : datascource,
              'nodeContent': 'title',
              'verticalLevel': verticalLevel,
              'collapsedChildren': false,
            });

          });

        })(jQuery);`;
      script.type = "text/javascript";
      document.body.append(script);
    }
  }, [treeData]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (props) => props.row.index + 1,
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (props) => moment(props.value).format("DD MMM, YYYY LT"),
      },
      {
        Header: "Wallet Address",
        accessor: "wallet_address",
        Cell: (props) => formatTransactionHash(props.value),
      },
      {
        Header: "Level",
        accessor: "tree_depth",
      },
      {
        Header: "Total Purchase",
        accessor: "total_purchase",
        Cell: (props) => "$" + " " + props.value,
      },
    ],
    []
  );

  const mTable = useTable(
    {
      columns,
      data: tableData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <GlobalStyles />

      <section className="container pt-0 mt-3 pt-md-5 mt-md-5 pb-0">
        <div className="profile-referral p-0">
          <h2 className="text-center">My Downlines</h2>
          <div className="chart-container"></div>
        </div>
        <div className="bg-white rounded">
          <TableComponent mTable={mTable} title="Direct Referrals" />
        </div>
      </section>
    </div>
  );
};

export default Refferal;
