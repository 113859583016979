export const Coins = (height = 48, color = '#fff') => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 4C16 5.10457 13.3137 6 10 6C6.68629 6 4 5.10457 4 4M16 4C16 2.89543 13.3137 2 10 2C6.68629 2 4 2.89543 4 4M16 4V8M4 4V8C4 9.10457 6.68629 10 10 10C13.3137 10 16 9.10457 16 8M20 9.8862C20 10.9908 17.3137 11.8862 14 11.8862C10.6863 11.8862 8 10.9908 8 9.8862V13.8862M20 9.8862C20 9.01538 18.3304 8.27456 16 8M20 9.8862V13.8862C20 14.9908 17.3137 15.8862 14 15.8862C10.6863 15.8862 8 14.9908 8 13.8862M4 15.7724C4 16.877 6.68629 17.7724 10 17.7724C13.3137 17.7724 16 16.877 16 15.7724V19.7725C16 20.877 13.3137 21.7725 10 21.7725C6.68629 21.7725 4 20.877 4 19.7725V15.7724ZM4 15.7724C4 14.9016 5.66962 14.1608 8 13.8862" stroke={color} stroke-width="1" />
  </svg>
)

export const ChartBars = (height = 48, color = '#fff') => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 18L8.64645 11.3536C8.84171 11.1583 9.15829 11.1583 9.35355 11.3536L12.6464 14.6464C12.8417 14.8417 13.1583 14.8417 13.3536 14.6464L21 7M16 6H21.5C21.7761 6 22 6.22386 22 6.5V12" stroke={color} stroke-width="1" stroke-linecap="round" />
  </svg>
)

export const Referral = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 15C5.34315 15 4 16.3431 4 18C4 19.6569 5.34315 21 7 21C8.65685 21 10 19.6569 10 18C10 16.3431 8.65685 15 7 15ZM7 15V14C7 12.8954 7.89543 12 9 12H15C16.1046 12 17 12.8954 17 14V15M12 9C13.6569 9 15 7.65685 15 6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6C9 7.65685 10.3431 9 12 9ZM12 9V12M17 15C15.3431 15 14 16.3431 14 18C14 19.6569 15.3431 21 17 21C18.6569 21 20 19.6569 20 18C20 16.3431 18.6569 15 17 15Z" stroke={color} stroke-width="1" stroke-linecap="round" />
  </svg>
)

export const BarsUser = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 13L15 10M23 13C23 19.0751 18.0751 24 12 24C5.92487 24 1 19.0751 1 13C1 6.92488 5.92487 2.00001 12 2.00001M19 13C19 16.866 15.866 20 12 20C8.13401 20 5 16.866 5 13C5 9.13402 8.13401 6.00001 12 6.00001M15 9.50001V5.11958C15 4.98498 15.0543 4.85607 15.1505 4.76199L18.1505 1.83017C18.4671 1.52076 19 1.74508 19 2.18777V5.50001C19 5.77615 19.2239 6.00001 19.5 6.00001H22.8122C23.2549 6.00001 23.4792 6.53288 23.1698 6.84948L20.238 9.84948C20.1439 9.94574 20.015 10 19.8804 10H15.5C15.2239 10 15 9.77615 15 9.50001Z" stroke={color} stroke-width="1" stroke-linecap="round" />
  </svg>
)

export const Briefcase = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 8H6.2C5.0799 8 4.51984 8 4.09202 8.21799C3.71569 8.40973 3.40973 8.71569 3.21799 9.09202C3 9.51984 3 10.0799 3 11.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H8M8 8H16M8 8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8M8 8V21M16 8H17.8C18.9201 8 19.4802 8 19.908 8.21799C20.2843 8.40973 20.5903 8.71569 20.782 9.09202C21 9.51984 21 10.0799 21 11.2V17.8C21 18.9201 21 19.4802 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H16M16 8V21M8 21H16" stroke={color} stroke-width="1" stroke-linecap="round" />
  </svg>
)

export const PieChartIcon = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 21.9999C14.2503 21.9999 17.7265 18.6853 17.9846 14.5C18.0016 14.2244 17.7761 13.9999 17.5 13.9999H10.5C10.2239 13.9999 10 13.7761 10 13.4999V6.49993C10 6.22379 9.77553 5.99831 9.49991 6.01531C5.31459 6.27345 2 9.74961 2 13.9999C2 18.4182 5.58172 21.9999 10 21.9999Z" stroke={color} stroke-width="1" />
    <path d="M21.9846 9.49984C21.7367 5.47991 18.52 2.26325 14.5001 2.01531C14.2245 1.99831 14 2.22379 14 2.49993V9.49993C14 9.77607 14.2239 9.99993 14.5 9.99993L21.5 9.99993C21.7761 9.99993 22.0016 9.77546 21.9846 9.49984Z" stroke={color} stroke-width="1" />
  </svg>
)

export function Wallet(height = 48, color = "#fff") {
  return (
    <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H5.2C4.0799 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.0799 2 7.2V16.8C2 17.9201 2 18.4802 2.21799 18.908C2.40973 19.2843 2.71569 19.5903 3.09202 19.782C3.51984 20 4.0799 20 5.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V14.5M15.6 14H20.4C20.9601 14 21.2401 14 21.454 13.891C21.6422 13.7951 21.7951 13.6422 21.891 13.454C22 13.2401 22 12.9601 22 12.4V11.6C22 11.0399 22 10.7599 21.891 10.546C21.7951 10.3578 21.6422 10.2049 21.454 10.109C21.2401 10 20.9601 10 20.4 10H15.6C15.0399 10 14.7599 10 14.546 10.109C14.3578 10.2049 14.2049 10.3578 14.109 10.546C14 10.7599 14 11.0399 14 11.6V12.4C14 12.9601 14 13.2401 14.109 13.454C14.2049 13.6422 14.3578 13.7951 14.546 13.891C14.7599 14 15.0399 14 15.6 14Z" stroke={color} stroke-width="1" />
    </svg>
  )
}

export const ChartBars2 = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20V22ZM4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3H4ZM5.18404 20.564L5.63803 19.673H5.63803L5.18404 20.564ZM3.43597 18.816L4.32698 18.362L4.32698 18.362L3.43597 18.816ZM7 17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17H7ZM9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12H9ZM12 17C12 17.5523 12.4477 18 13 18C13.5523 18 14 17.5523 14 17H12ZM14 3C14 2.44772 13.5523 2 13 2C12.4477 2 12 2.44772 12 3L14 3ZM17 17C17 17.5523 17.4477 18 18 18C18.5523 18 19 17.5523 19 17H17ZM19 8C19 7.44772 18.5523 7 18 7C17.4477 7 17 7.44772 17 8H19ZM21 20H9.4V22H21V20ZM4 14.6V3H2V14.6H4ZM9.4 20C8.26339 20 7.47108 19.9992 6.85424 19.9488C6.24907 19.8994 5.90138 19.8072 5.63803 19.673L4.73005 21.455C5.32234 21.7568 5.96253 21.8826 6.69138 21.9422C7.40855 22.0008 8.2964 22 9.4 22V20ZM2 14.6C2 15.7036 1.99922 16.5914 2.05782 17.3086C2.11737 18.0375 2.24318 18.6777 2.54497 19.27L4.32698 18.362C4.19279 18.0986 4.10062 17.7509 4.05118 17.1458C4.00078 16.5289 4 15.7366 4 14.6H2ZM5.63803 19.673C5.07354 19.3854 4.6146 18.9265 4.32698 18.362L2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455L5.63803 19.673ZM9 17V12H7V17H9ZM14 17L14 3L12 3L12 17H14ZM19 17V8H17V17H19Z" fill={color} />
  </svg>

)

export const Doller = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 8C17 5.79086 15.2091 4 13 4H11C8.79086 4 7 5.79086 7 8C7 10.2091 8.79086 12 11 12H13C15.2091 12 17 13.7909 17 16C17 18.2091 15.2091 20 13 20H11C8.79086 20 7 18.2091 7 16M12 2L12 22" stroke={color} stroke-width="1" stroke-linecap="round" />
  </svg>
)

export const Safe = (height = 48, color = "#fff") => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_803_17986)">
      <path d="M7 17V7C7 5.89543 7.89543 5 9 5H17C18.1046 5 19 5.89543 19 7V17C19 18.1046 18.1046 19 17 19H9C7.89543 19 7 18.1046 7 17ZM7 17H6C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15H7M7 10H6C5.44772 10 5 9.55228 5 9C5 8.44772 5.44772 8 6 8H7M9 23H15C17.8003 23 19.2004 23 20.27 22.455C21.2108 21.9757 21.9757 21.2108 22.455 20.27C23 19.2004 23 17.8003 23 15V9C23 6.19974 23 4.79961 22.455 3.73005C21.9757 2.78924 21.2108 2.02433 20.27 1.54497C19.2004 1 17.8003 1 15 1H9C6.19974 1 4.79961 1 3.73005 1.54497C2.78924 2.02433 2.02433 2.78924 1.54497 3.73005C1 4.79961 1 6.19974 1 9V15C1 17.8003 1 19.2004 1.54497 20.27C2.02433 21.2108 2.78924 21.9757 3.73005 22.455C4.79961 23 6.19974 23 9 23Z" stroke={color} stroke-width="1" stroke-linecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_803_17986">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)