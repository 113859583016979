import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/animated.css";

import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/style_grey.scss';
import './assets/Jquery.OrgChart.scss';
import App from './App';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { BrowserRouter } from 'react-router-dom';

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "QuickPay",
      description: 'QuickPay',
      url: 'https://app.quickpay.international/',
      icons: ['https://app.quickpay.international/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-white.e1c587e0.png&w=640&q=75']
    }
  }),
  chains: [{
    chainId: 56,
    name: 'BNB Smart Chain Mainnet',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed1.binance.org/'
  }, {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'BNB',
    rpcUrl: 'https://data-seed-prebsc-1-s2.bnbchain.org:8545'
  }],
  projectId: "002a6f95291a4edb1732789c2d95582b"
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
