import React, { useContext } from "react";
import { createGlobalStyle, keyframes } from "styled-components";
import {
  Coins,
  ChartBars,
  Referral,
  BarsUser,
  Briefcase,
  PieChartIcon,
  Wallet,
  ChartBars2,
  Doller,
  Safe,
} from "../assets/dashboard";
import Reveal from "react-awesome-reveal";
import { UserContext } from "../App";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: start;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    left: 40%;
    top: 40%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const iconSize = 40;
const iconSize2 = 80;
const iconColor = "#fff";
const iconColor2 = "rgba(6,174,177,0.1)";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const cards = [
  {
    name: "My Purchase ($)",
    icon: ChartBars(iconSize, iconColor),
    icon2: ChartBars(iconSize2, iconColor2),
    doller: true,
    key: "total_purchase",
  },
  {
    name: "Direct Referrals",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "direct_referral",
  },
  {
    name: "Upline Income",
    icon: Coins(iconSize, iconColor),
    icon2: Coins(iconSize2, iconColor2),
    doller: true,
    key: "global_upline_earning",
  },
  {
    name: "Downline Income",
    icon: Coins(iconSize, iconColor),
    icon2: Coins(iconSize2, iconColor2),
    doller: true,
    key: "global_downline_earning",
  },
  {
    name: "Referral Income",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "referral_earning",
    doller: true,
  },
  {
    name: "Rank Income",
    icon: BarsUser(iconSize, iconColor),
    icon2: BarsUser(iconSize2, iconColor2),
    doller: true,
    key: "rank_earning",
  },
  {
    name: "Royalty Bonus",
    icon: Briefcase(iconSize, iconColor),
    icon2: Briefcase(iconSize2, iconColor2),
    doller: true,
    key: "royalty_earning",
  },
  {
    name: "Sponsor Royalty",
    icon: PieChartIcon(iconSize, iconColor),
    icon2: PieChartIcon(iconSize2, iconColor2),
    doller: true,
    key: "sponsor_royalty_earning",
  },
  {
    name: "Performace Bonus",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    doller: true,
    key: "performance_earning",
  },
  {
    name: "Special Rank Income",
    value: "$ 0",
    icon: ChartBars2(iconSize, iconColor),
    icon2: ChartBars2(iconSize2, iconColor2),
    key: "special_rank_earning",
  },
];

const Dashboard = () => {
  const { data: customerData } = useContext(UserContext);

  return (
    <div>
      <GlobalStyles />

      <section className="container pt-0 mt-3 pt-md-5 mt-md-5 pb-0">
        <div className="row">
          <div className="col-md-9 col-12 order-md-first order-last px-0 px-md-4">
            <div className="row">
              <div className="col-md-8 mb-4 mb-md-0">
                <div className="border text-center overflow-hidden rounded position-relative">
                  <div
                    style={{ border: "2px solid rgba(6,174,177,1)" }}
                    className="bg-white rounded p-3"
                  >
                    <h2 className="mb-3">Wallet Balance</h2>
                    <div className="row mb-2">
                      <div className="col-6 d-flex justify-content-center border-end border-opacity-30">
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Balance</p>
                          <p className="m-0 fs-25 fw-600 text-black">
                            $ {customerData["wallet_balance"]}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Withdrawal</p>
                          <span className="fs-25 fw-600 text-black">
                            $ {customerData["total_withdraw"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cards?.map((card, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-4">
                  <div className="feature-box h-100 d-flex align-items-center justify-content-between f-boxed style-3">
                    <div className="text mt-2">
                      <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={100}
                        duration={600}
                        triggerOnce
                      >
                        <p className="fs-20 mb-2">{card.name}</p>
                      </Reveal>
                      <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={200}
                        duration={600}
                        triggerOnce
                      >
                        <p style={{ color: "black" }} className="fs-25 fw-600">
                          {card.doller ? "$ " : ""}{" "}
                          {customerData[card.key] ? customerData[card.key] : 0}
                        </p>
                      </Reveal>
                    </div>
                    <Reveal
                      className="onStep"
                      keyframes={fadeInUp}
                      delay={0}
                      duration={600}
                      triggerOnce
                    >
                      <div className="mcard-icon">{card.icon}</div>
                    </Reveal>

                    <i className="wm">{card.icon2}</i>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3 order-md-last order-first col-12 mb-4 mb-md-0 px-0 px-md-4 mt-xs-4">
            <div className="border overflow-hidden rounded position-relative">
              <div className="d-flex gap-3 align-items-center p-3 bg-white">
                <img style={{ height: "70px" }} src="/favicon.png" />
                <div>
                  <h3 className="mb-0">Rank {customerData?.rank}</h3>
                  <p className="mb-0">Active User</p>
                </div>
              </div>
              <div
                style={{ backgroundColor: "rgba(6,174,177,1)" }}
                className="text-center rounded text-white p-3"
              >
                <h2 className="mb-0 text-white">Refer Now</h2>
                <p style={{ wordWrap: "break-word", fontSize: "13px" }}>
                  https://echobit.com/register?sponsoraddress=
                  {customerData["wallet_address"]}
                </p>
                <button className="btn-second m-auto">Share Now</button>
              </div>
            </div>

            <div
              style={{
                border: "2px solid rgba(242,153,58, 1)",
                height: "318px",
              }}
              className="bg-white mt-4 text-center overflow-hidden rounded position-relative"
            >
              <div className="rounded p-3">
                <h2 className="mb-0 text-center">Total Income</h2>
                <p className="text-center fs-25 fw-600 text-black mb-0">
                  $ {customerData["total_earning"]}
                </p>
              </div>
              <img src="/img/dashboard1.jpg" className="w-100" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Dashboard;
